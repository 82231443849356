import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const importBuildTarget = (): Promise<any> => {
  if (process.env.REACT_APP_BUILD_TARGET === "app") {
    return import("./app/index");
  }
  // if (process.env.REACT_APP_BUILD_TARGET === "riskpilot") {
  //   return import("./riskpilot/index");
  // }
  else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}


// Import the entry point and render it's default export 
importBuildTarget().then(({ default: Environment }) =>
  root.render(<Environment />)
);

